import React from "react";
import LazyLoad from "react-lazyload";

export default function LeonidsCTa() {
  return (
    <section className="bg-black">
      <svg
        className="absolute opacity-20"
        width="100%"
        height="100%"
        viewBox="0 0 400 400"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_17_23)">
          <g filter="url(#filter0_f_17_23)">
            <path d="M271.4 0H400V322.2L320 290L271.4 0Z" fill="#fff"></path>
            <path
              d="M400 322.2V400H320H244L320 290L400 322.2Z"
              fill="#fff"
            ></path>
            <path d="M244 400H0V166L320 290L244 400Z" fill="#fff"></path>
            <path d="M0 0H271.4L320 290L0 166V0Z" fill="#fff"></path>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_f_17_23"
            x="-159.933"
            y="-159.933"
            width="719.867"
            height="719.867"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            ></feBlend>
            <feGaussianBlur
              stdDeviation="79.9667"
              result="effect1_foregroundBlur_17_23"
            ></feGaussianBlur>
          </filter>
        </defs>
      </svg>
      <svg
        className="absolute opacity-30"
        viewBox="0 0 960 637"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_f_1_167)">
          <ellipse
            cx="479.5"
            cy="318.5"
            rx="118.5"
            ry="118.5"
            transform="rotate(-90 479.5 318.5)"
            fill="#3C459A"
            fillOpacity="0.5"
          ></ellipse>
        </g>
        <mask
          id="mask0_1_167"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="32"
          width="960"
          height="573"
        >
          <rect
            y="32"
            width="960"
            height="573"
            fill="url(#paint0_radial_1_167)"
          ></rect>
        </mask>
        <g mask="url(#mask0_1_167)">
          <rect x="123.5" y="41.5" width="79" height="79" stroke="white"></rect>
          <rect x="202.5" y="41.5" width="79" height="79" stroke="white"></rect>
          <rect x="281.5" y="41.5" width="79" height="79" stroke="white"></rect>
          <rect x="360.5" y="41.5" width="79" height="79" stroke="white"></rect>
          <rect x="439.5" y="41.5" width="79" height="79" stroke="white"></rect>
          <rect x="518.5" y="41.5" width="79" height="79" stroke="white"></rect>
          <rect
            x="597.5"
            y="41.5"
            width="79"
            height="79"
            fill="white"
            fill-opacity="0.25"
            stroke="white"
          ></rect>
          <rect x="676.5" y="41.5" width="79" height="79" stroke="white"></rect>
          <rect x="755.5" y="41.5" width="79" height="79" stroke="white"></rect>
          <rect
            x="123.5"
            y="120.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="202.5"
            y="120.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="281.5"
            y="120.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="360.5"
            y="120.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="439.5"
            y="120.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="518.5"
            y="120.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="597.5"
            y="120.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="676.5"
            y="120.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="755.5"
            y="120.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="123.5"
            y="199.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="202.5"
            y="199.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="281.5"
            y="199.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="360.5"
            y="199.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="439.5"
            y="199.5"
            width="79"
            height="79"
            fill="white"
            fill-opacity="0.25"
            stroke="white"
          ></rect>
          <rect
            x="518.5"
            y="199.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="597.5"
            y="199.5"
            width="79"
            height="79"
            fill="white"
            fill-opacity="0.25"
            stroke="white"
          ></rect>
          <rect
            x="676.5"
            y="199.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="755.5"
            y="199.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="123.5"
            y="278.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="202.5"
            y="278.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="281.5"
            y="278.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="360.5"
            y="278.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="439.5"
            y="278.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="518.5"
            y="278.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="597.5"
            y="278.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="676.5"
            y="278.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="755.5"
            y="278.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="123.5"
            y="357.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="202.5"
            y="357.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="281.5"
            y="357.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="360.5"
            y="357.5"
            width="79"
            height="79"
            fill="white"
            fill-opacity="0.25"
            stroke="white"
          ></rect>
          <rect
            x="439.5"
            y="357.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="518.5"
            y="357.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="597.5"
            y="357.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="676.5"
            y="357.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="755.5"
            y="357.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="123.5"
            y="436.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="202.5"
            y="436.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="281.5"
            y="436.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="360.5"
            y="436.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="439.5"
            y="436.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="518.5"
            y="436.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="597.5"
            y="436.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="676.5"
            y="436.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="755.5"
            y="436.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="123.5"
            y="515.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="202.5"
            y="515.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="281.5"
            y="515.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="360.5"
            y="515.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="439.5"
            y="515.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="518.5"
            y="515.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="597.5"
            y="515.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="676.5"
            y="515.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
          <rect
            x="755.5"
            y="515.5"
            width="79"
            height="79"
            stroke="white"
          ></rect>
        </g>
        <defs>
          <filter
            id="filter0_f_1_167"
            x="161"
            y="1.14441e-05"
            width="637"
            height="637"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            ></feBlend>
            <feGaussianBlur
              stdDeviation="100"
              result="effect1_foregroundBlur_1_167"
            ></feGaussianBlur>
          </filter>
          <radialGradient
            id="paint0_radial_1_167"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(480 318.5) rotate(90) scale(353.19 591.732)"
          >
            <stop stopColor="#D9D9D9" stopOpacity="0.2"></stop>
            <stop offset="0.802083" stopColor="#D9D9D9" stopOpacity="0"></stop>
          </radialGradient>
        </defs>
      </svg>
      <div className="flex flex-col justify-center flex-1 px-8 py-6 mx-auto lg:py-10 md:px-12 lg:flex-none lg:px-24 max-w-7xl">
        <div className="justify-center">
          <div>
       
              <p
                className="text-white text-2xl  sm:text-4xl"
                style={{
                  fontFamily: "rubik",
                  fontDisplay: "swap",
                }}
                data-aos="fade-right"
                data-aos-once="true"
              >
                Leonids Labs value and unique approach
              </p>
              <p
                className="max-w-xl mt-4 text-lg text-white"
                style={{ fontFamily: "rubik" }}
                data-aos="fade-up"
                data-aos-duration="3000"
                data-aos-once="true"
              >
                The distinct value that sets Leonids Labs apart, showcasing a
                unique approach to cybersecurity solutions.
              </p>
          </div>
        </div>
        <div className="mx-auto mt-12 text-left">
          <div>
            <div className="grid grid-cols-1 text-sm gap-x-2 gap-y-14 lg:grid-cols-2 lg:gap-20">
              <article>
                <div className="space-y-3">
                  <div className="gap-2 lg:inline-flex lg:items-center">
                    <p
                      className="mt-2 text-2xl font-medium leading-6 text-white"
                      style={{ fontFamily: "rubik" }}
                      data-aos="fade-up"
                      data-aos-duration="3000"
                      data-aos-once="true"
                    >
                      <i
                        class="fa-solid fa-microchip mr-2"
                        style={{ color: "#ffffff" }}
                      ></i>
                      Cutting-edge solutions
                    </p>
                  </div>
                  <p
                    className="mt-2 text-base text-white"
                    style={{ fontFamily: "rubik" }}
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    data-aos-once="true"
                  >
                    Leonids Labs showcases cutting-edge solutions, empowering
                    enterprises, government agencies, NGOs & individuals to
                    safeguard assets against evolving cyber threats.
                  </p>
                </div>
              </article>
              <article>
                <div className="space-y-3">
                  <div className="gap-2 lg:inline-flex lg:items-center">
                    <p
                      className="mt-2 text-2xl font-medium leading-6 text-white"
                      style={{ fontFamily: "rubik" }}
                      data-aos="fade-up"
                      data-aos-duration="3000"
                      data-aos-once="true"
                    >
                      <i
                        class="fa-solid fa-crosshairs mr-2"
                        style={{ color: "#ffffff" }}
                      ></i>
                      Unusual cyber operations
                    </p>
                  </div>
                  <p
                    className="mt-2 text-base text-white"
                    style={{ fontFamily: "rubik" }}
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    data-aos-once="true"
                  >
                    Specializing in distinctive cyber operations, our sharp
                    intelligence safeguards your world in unconventional ways.
                    Anticipating the unexpected.
                  </p>
                </div>
              </article>
              <article>
                <div className="space-y-3">
                  <div className="gap-2 lg:inline-flex lg:items-center">
                    <p
                      className="mt-2 text-2xl font-medium leading-6 text-white"
                      style={{ fontFamily: "rubik" }}
                      data-aos="fade-up"
                      data-aos-duration="3000"
                      data-aos-once="true"
                    >
                      <i
                        class="fa-solid fa-chess-board mr-2"
                        style={{ color: "#ffffff" }}
                      ></i>
                      Strategic approach
                    </p>
                  </div>
                  <p
                    className="mt-2 text-base text-white"
                    style={{ fontFamily: "rubik" }}
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    data-aos-once="true"
                  >
                    Elevate your strategic game with our expertise in crafting
                    distinctive approaches, Our sharp services is your goto to
                    secure your assets.
                  </p>
                </div>
              </article>
              <article>
                <div className="space-y-3">
                  <div className="gap-2 lg:inline-flex lg:items-center">
                    <p
                      className="mt-2 text-2xl font-medium leading-6 text-white"
                      style={{ fontFamily: "rubik" }}
                      data-aos="fade-up"
                      data-aos-duration="3000"
                      data-aos-once="true"
                    >
                      <i
                        class="fa-solid fa-file-shield mr-2"
                        style={{ color: "#ffffff" }}
                      ></i>
                      Detailed precision
                    </p>
                  </div>
                  <p
                    className="mt-2 text-base text-white"
                    style={{ fontFamily: "rubik" }}
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    data-aos-once="true"
                  >
                    Discover precision in our detailed reports. We analyze
                    threats with meticulous attention, providing actionable
                    insights to fortify your digital defenses.
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
