import React from "react";
import "@fortawesome/fontawesome-free";

export default function LeonidsCustomServices() {
  return (
    <div
      className="flex items-center justify-center max-w-full px-8 py-4 bg-gradient-to-t from-zinc-950 to-zinc-800 mx-auto flex-col  "
      style={{ fontFamily: "rubik" }}
    >
      <p className="text-white text-2xl md:text-3xl mt-3">
        And much more 
      </p>

      <div class="relative items-center w-full px-5 py-4 mx-auto md:px-12 lg:px-20 max-w-7xl ">
        <div class="grid w-full grid-cols-1 mx-auto lg:grid-cols-4">


          <div className="max-w-md p-3 mx-auto" data-aos="fade-up" data-aos-duration="3000" data-aos-once="true">
            <p className="mt-5 text-xl font-medium leading-6 text-white flex">
              <img
                src="/images/icons/smartphone.png"
                className="mr-1"
                style={{ width: "35px", height: "35px", color: "white" }}
                alt="Leonids Labs Encrypted Mercury phone icon"
              />
              Mercury - Encrypted Communications.
            </p>
            <p className="mt-1 text-md text-white text-center">
              Leonids Labs' Mercury custom encrypted devices.
            </p>
          </div>

          <div className="max-w-md p-3 mx-auto" data-aos="fade-up" data-aos-duration="3000" data-aos-once="true">
            <p className="mt-5 text-xl font-medium leading-6 text-white flex">
              <img
                src="/images/icons/verified.png"
                className="mr-1"
                style={{ width: "35px", height: "35px", color: "white" }}
                alt="Leonids Labs Online reputation management Verified Icon"
              />
              Online Reputation Management.
            </p>
            <p className="mt-1 text-md text-white text-center">
              Ensuring a Secured and Positive online presence.
            </p>
          </div>

          <div className="max-w-md p-3 mx-auto" data-aos="fade-up" data-aos-duration="3000" data-aos-once="true">
            <p className="mt-5 text-xl font-medium leading-6 text-white flex">
              <img
                src="/images/icons/fingerprint.png"
                className="mr-1"
                style={{ width: "50px", height: "50px", color: "white" }}
                alt="Leonids Labs Mobile devices forensics Icon"
              />
              Mobile devices Forensic Analysis.
            </p>
            <p className="mt-1 text-md text-white text-center">
              Uncover and detect digital evidence in mobile environments.
            </p>
          </div>

          <div className="max-w-md p-3 mx-auto" data-aos="fade-up" data-aos-duration="3000" data-aos-once="true">
            <p className="mt-5 text-xl font-medium leading-6 text-white flex">
              <img
                src="/images/icons/equalizer.png"
                className="mr-1"
                style={{ width: "45px", height: "45px", color: "white" }}
                alt="Leonids Labs custom solutions"
              />
              Leonids Labs' custom solutions.
            </p>
            <p className="mt-1 text-md text-white text-center">
              Tailored custom solutions & strategies for Your Needs.
            </p>
          </div>


        </div>
      </div>
    </div>
  );
}
