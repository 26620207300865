import "./App.css";
import "../src/fonts/fonts.css"
import LeonidsNav from "./components/LeonidsNav";
import Hero from "./components/Hero";
import LeonidsCta from "./components/LeonidsCta";
import LeonidsServices from "./components/LeonidsServices";
import LeonidsCustomServices from "./components/LeonidsCustomServices";
import LeonidsSocial from "./components/LeonidsSocial";
import LeonidsFooter from "./components/LeonidsFooter";




function App() {
  return (
    <div className="">
      <LeonidsNav></LeonidsNav>
      <Hero></Hero>
      <LeonidsCta></LeonidsCta>
      <LeonidsServices></LeonidsServices>
      <LeonidsCustomServices></LeonidsCustomServices>
      <LeonidsSocial></LeonidsSocial>
      <LeonidsFooter></LeonidsFooter>
    </div>
  );
}

export default App;
