import React from "react";


export default function LeonidsServices() {
  return (
    <div
      className="flex items-center justify-center max-w-full  px-8 py-7 mx-auto flex-col"
      style={{ fontFamily: "rubik" }}
      id="solutions"
    >
      <p
        className="mb-8 text-center text-3xl md:text-5xl "
        style={{ fontFamily: "rubik" }}
      >
        Leonids Labs Services
      </p>
      <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
        <div className="rounded-xl flex flex-col mx-2 p-6 md:p-12 bg-white border shadow-xl rounded-4xl shadow-gray-500/10 lg:p-12 shadow-black-500/10 lg:p-12 bg-white bg-opacity-40 backdrop-blur-md rounded drop-shadow-xl">
          <div>
            <div className="flex justify-between">
              <div className="flex items-center gap-2 ml-7 ">
                <img
                  src="/images/icons/iceberg.png"
                  style={{ width: "60px", height: "60px" }}
                  alt="Leonids Labs Dark web Icebrg."
                />
                <p className=" md:text-lg text-xl justify-center text-center font-medium text-black lg:text-3xl">
                  Leonids intel
                </p>
              </div>
            </div>
            <p className="mt-4 md:mt-8 text-lg text-gray-500 text-center">
              Leonids Labs Threath intel services
            </p>
          </div>
          <div className="order-last">
            <p className="mt-6 md:mt-8 text-lg font-medium text-black lg:text-2xl">
              Solutions
            </p>
            <ul
              className="order-last gap-4 mt-2 md:mt-4 space-y-2 md:space-y-3 text-gray-500 list-none"
              role="list"
            >
              <li className="flex items-center gap-2">
                <i
                  className="fa-solid fa-circle-check"
                  style={{ color: "#32c367" }}
                ></i>
                <span>Monitor Potential Threats.</span>
              </li>
              <li className="flex items-center gap-2">
                <i
                  className="fa-solid fa-circle-check"
                  style={{ color: "#32c367" }}
                ></i>
                <span>Monitor Compromised Credentials.</span>
              </li>
              <li className="flex items-center gap-2">
                <i
                  className="fa-solid fa-circle-check"
                  style={{ color: "#32c367" }}
                ></i>
                <span>Efficiently De-anonymize Actors.</span>
              </li>
              <li className="flex items-center gap-2">
                <i
                  className="fa-solid fa-circle-check"
                  style={{ color: "#32c367" }}
                ></i>
                <span>Breach Assessment, Mitigation & Response.</span>
              </li>
              <li className="flex items-center gap-2">
                <i
                  className="fa-solid fa-circle-check"
                  style={{ color: "#32c367" }}
                ></i>
                <span>Cyber Threats briefings.</span>
              </li>
            </ul>
          </div>
          <div className="flex mt-6 justify-center">
            <button className="px-6 py-2.5 text-center text-white duration-200 bg-black border-2 border-black rounded-lg h-12 inline-flex hover:bg-transparent hover:border-black hover:text-black focus:outline-none focus-visible:outline-black text-base focus-visible:ring-black">
              <a href="https://calendly.com/leonidslabs/leonidslabs-sales">
                Contact sales
              </a>
            </button>
          </div>
        </div>

        <div className="rounded-xl flex flex-col mx-2 p-6 md:p-12 bg-white border shadow-xl rounded-4xl shadow-gray-500/10 lg:p-12 shadow-black-500/10 lg:p-12 bg-white bg-opacity-40 backdrop-blur-md rounded drop-shadow-xl">
          <div>
            <div className="flex justify-between">
              <div className="flex items-center gap-2 ml-7">
                <img
                  src="/images/icons/cloud-server.png"
                  style={{ width: "50px", height: "50px" }}
                  alt="Leonids Labs Cloud cloud."
                />
                <p className=" md:text-lg text-xl justify-center text-center font-medium text-black lg:text-3xl">
                  Leonids Cyber
                </p>
              </div>
            </div>
            <p className="mt-4 md:mt-8 text-lg text-center text-gray-500">
              Leonids Labs Cyber services
            </p>
          </div>
          <div className="order-last">
            <p className="mt-6 md:mt-8 text-lg font-medium text-black lg:text-2xl">
              Solutions
            </p>
            <ul
              className="order-last gap-4 mt-2 md:mt-4 space-y-2 md:space-y-3 text-gray-500 list-none"
              role="list"
            >
              <li className="flex items-center gap-2">
                <i
                  className="fa-solid fa-circle-check"
                  style={{ color: "#32c367" }}
                ></i>
                <span>Modern Web Applications Security audits.</span>
              </li>
              <li className="flex items-center gap-2">
                <i
                  className="fa-solid fa-circle-check"
                  style={{ color: "#32c367" }}
                ></i>
                <span>Attack Surface Discovery & Management.</span>
              </li>
              <li className="flex items-center gap-2">
                <i
                  className="fa-solid fa-circle-check"
                  style={{ color: "#32c367" }}
                ></i>
                <span>Monitoring & Detection.</span>
              </li>
              <li className="flex items-center gap-2">
                <i
                  className="fa-solid fa-circle-check"
                  style={{ color: "#32c367" }}
                ></i>
                <span>Incident Response.</span>
              </li>
              <li className="flex items-center gap-2">
                <i
                  className="fa-solid fa-circle-check"
                  style={{ color: "#32c367" }}
                ></i>
                <span>LLCERT Web vulnerabilities briefings.</span>
              </li>
            </ul>
          </div>
          <div className="flex mt-6 justify-center">
            <button className="px-6 py-2.5 text-center text-white duration-200 bg-black border-2 border-black rounded-lg h-12 inline-flex hover:bg-transparent hover:border-black hover:text-black focus:outline-none focus-visible:outline-black text-base focus-visible:ring-black">
              <a href="https://calendly.com/leonidslabs/leonidslabs-sales">
                Contact sales
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
