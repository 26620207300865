import React from 'react';

// Notice the version.
const year = "2020-2024"
const version = "v2.1.1" // Solutions alt edit

const LeonidsFooter = () => {
  return (
    <footer className="bg-black" >
      <div className="px-4 py-7 mx-auto overflow-hidden max-w-7xl sm:px-6 lg:px-8">
        <nav className="flex flex-wrap justify-center -mx-5 -my-2" aria-label="Footer">
          <div className="px-5 py-2">
            <a href="/contact" className="text-sm text-gray-500 hover:text-blue-600">
              Contact us
            </a>
          </div>

          <div className="px-5 py-2">
            <a href="https://Blog.leonidslabs.com" className="text-sm text-gray-500 hover:text-blue-600">
              Blog
            </a>
          </div>

          <div className="px-5 py-2">
            <a href="/about-us" className="text-sm text-gray-500 hover:text-blue-600">
              About us
            </a>
          </div>


        </nav>
        <div className="flex justify-center mt-8 space-x-6">
          <span className="inline-flex justify-center w-full gap-3 m-auto md:justify-start md:w-auto">
            <a className="w-6 h-6 transition fill-black hover:text-blue-500" href='https://www.github.com/leonids-labs'>
              <span className="sr-only" >github</span>
              <ion-icon  className="w-5 h-5 md hydrated" name="logo-github" role="img" aria-label="logo github"></ion-icon>
            </a>
            <a className="w-6 h-6 transition fill-black hover:text-blue-500" href='https://www.linkedin.com/company/leonids-labs'>
              <span className="sr-only">Linkedin</span>
              <ion-icon className="w-5 h-5 md hydrated" name="logo-linkedin" role="img" aria-label="logo linkedin"></ion-icon>
            </a>
            <a className="w-6 h-6 transition fill-black hover:text-blue-500" href='https://www.twitter.com/LeonidsLabs'>
              <span className="sr-only">twitter</span>
              <ion-icon className="w-5 h-5 md hydrated" name="logo-twitter" role="img" aria-label="logo twitter"></ion-icon>
            </a>
          </span>
        </div>
        <p className="mt-3 text-center">
          <span className="mx-auto mt-2 text-sm text-gray-500">
            Copyright Leonids Labs © {year}
            <a href="https://LeonidsLabs.com" className="mx-2 text-blue-500 hover:text-gray-500" rel="noopener noreferrer">
              Leonids Labs {version}
            </a>
            - Running in the background since 2020.
          </span>
        </p>
      </div>
    </footer>
  );
};

export default LeonidsFooter;
