import React from "react";
import "@fortawesome/fontawesome-free";
import LeonidsTyper from "./sub-components/LeonidsTyper";
import { Button } from "@material-tailwind/react";

export default function LeonidsSocial() {
  return (
    <section style={{ fontFamily: "rubik" }}>
      <div className="flex flex-col justify-center flex-1 px-8 py-8 lg:py-10 md:px-12 lg:flex-none lg:px-24">
        <div>
          <div className="relative items-center gap-12 mx-auto">
            <div className="max-w-xl mx-auto text-center">
              <div>
                <p className="md:text-5xl text-3xl  tracking-tighter text-black">
                  <LeonidsTyper></LeonidsTyper>
                </p>
              </div>
              <div className="flex items-center justify-center w-full pt-8 mx-auto md:pt-6">
                <form
                  className="flex flex-col items-center justify-center max-w-sm mx-auto"
                  action=""
                >
                  <div className="flex  w-full gap-1 mt-2 sm:flex-row">
                    <Button
                      variant="gradient"
                      style={{ fontFamily: "rubik" }}
                      className="font-light"
                    >
                      <a href="https://calendly.com/leonidslabs">Contact Us</a>
                    </Button>
                    <Button
                      variant="gradient"
                      style={{ fontFamily: "rubik" }}
                      className="font-light"
                    >
                      <a href="https://blog.leonidslabs.com">Blog</a>
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <p className="text-center text-lg font-bold mt-5">Leonids Labs social media feed</p>

          <div className="pt-[10px] flex space-x-4 mx-8 justify-center">
            <a
              className="uppercase"
              href="https://www.linkedin.com/company/leonids-labs/"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
                className="w-[35px] h-[35px] mx-1"
              >
                <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
              </svg>
            </a>

            <a
              className="uppercase text-lg"
              href="https://www.twitter.com/leonidslabs"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
                className="w-[35px] h-[35px] mx-1"
              >
                <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
