import { Typography } from '@material-tailwind/react';
import { TypeAnimation } from 'react-type-animation';

const LeonidsTyper = () => {
 

  return (
    <div className=''>
        Leonids Labs worked with
      <Typography variant='body1' className='text-black text-2xl md:text-3xl '>
      <TypeAnimation

  sequence={[
    "Government agencies.",
    900,
    "Security companies.",
    800,
    "Large size businesses & enterprises.",
    800,
    "Small & Medium-sized enterprises.",
    800,
    "Law firms.",
    800,
    "HNWIs & Celebrities",
    800,

  ]}
  
  style={{ fontFamily: 'Rubik' }}
  repeat={Infinity}
/>
        
</Typography>
    </div>
  );
};

export default LeonidsTyper;
