import LazyLoad from "react-lazyload";

export default function Hero() {
  return (
    <section className="">
      <div className="py-[35px]  bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px] ">
        <LazyLoad once height={"100%"} >
          <h1
            className="pt-[30px] text-center text-5xl md:text-7xl txt-black font-bold"
            style={{ fontFamily: "rubik" }}
          >
            Leonids Labs
          </h1>
          <p className="text-black text-lg text-center" style={{ fontFamily: "rubik" }}>We know first.</p>
          <p
            className="mt-8 text-center text-xl md:text-3xl text-black"
            style={{ fontFamily: "rubik" }}
          >
            Cutting edge Cyberspace security & Cyber intelligence solutions
          </p>
        </LazyLoad>

        <div
          className="pt-[30px] flex space-x-4 mx-8 justify-center"
          style={{ fontFamily: "rubik" }}
        >
          <a
            href="https://calendly.com/leonidslabs/leonidslabs-sales"
            className="items-center justify-center w-full px-6 py-2.5 text-center text-white duration-200 bg-black border-2 border-black rounded-full inline-flex hover:bg-transparent hover:border-black hover:text-black focus:outline-none lg:w-auto focus-visible:outline-black text-sm focus-visible:ring-black"
          >
            <div style={{ position: "relative" }}></div>
            Schedule a Catalogue/Demo
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              className="w-4 h-auto ml-2"
            >
              <path
                fillRule="evenodd"
                d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
}
