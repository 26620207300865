import React, { useState } from "react";
import { Typography } from "@material-tailwind/react";
import "../fonts/fonts.css";

export default function LeonidsNav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  function scrollToSolutions() {
    const solutionsSection = document.getElementById("solutions");
    if (solutionsSection) {
      solutionsSection.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }

  function toggleMobileMenu() {
    setMobileMenuOpen(!mobileMenuOpen);
  }

  return (
    <nav className="border-gray-200 pb-5 mb-[10px] mt-5 ">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto">
        <a href="/" className="flex items-center">
          <img
            src="/images/svg/leonidslabs.svg"
            className="h-[45px] w-[50px] mr-[15px] ml-2"
            alt="Leonids Labs logo."
          />
          <span
            className="text-black self-center text-2xl mt-2 font-bold"
            style={{ fontFamily: "Rubik" }}
          >
            Leonids Labs
          </span>
        </a>
        <button
          onClick={toggleMobileMenu}
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
          aria-controls="navbar-dropdown"
          aria-expanded={mobileMenuOpen}
        >
          <span className="sr-only">Toggle mobile menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`${
            mobileMenuOpen ? "block" : "hidden"
          } w-full md:block md:w-auto`}
          id="navbar-dropdown"
        >
          <ul className="text-black flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg  md:flex-row md:space-x-8 md:mt-0 md:border-0  ">
            <li>
              <a
                href="/contact"
                onClick={() => {
                  toggleMobileMenu(); // Close mobile menu after clicking
                }}
                className="uppercase block py-2 pl-3 pr-4 text-black font-bold rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
                style={{ fontFamily: "Rubik" }}
              >
                <Typography>Contact</Typography>
              </a>
            </li>
            <li>
              <a
                href="#solutions"
                onClick={() => {
                  scrollToSolutions();
                  toggleMobileMenu(); // Close mobile menu after clicking
                }}
                className="uppercase block py-2 pl-3 pr-4 text-black font-bold rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
                style={{ fontFamily: "Rubik" }}
              >
                <Typography>Solutions</Typography>
              </a>
            </li>
            <li>
              <a
                href="/about-us"
                onClick={() => {
                  toggleMobileMenu();
                }}
                className="uppercase block py-2 pl-3 pr-4 text-black font-bold rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
                style={{ fontFamily: "Rubik" }}
              >
                <Typography>About us</Typography>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
